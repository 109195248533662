import Btn from "../../templates/button";
import img1 from "../../../assets/images/cta-1.svg";
import { Container, Row, Col, Image } from "react-bootstrap";

const CTA = () => {
  return (
    <div className="cta-section">
      <Container>
        <div className="cta">
          <Row className="align-items-center justify-content-between flex-column-reverse flex-lg-row">
            <Col lg={6}>
              <div className="cta-text text-center text-lg-start">
                <h1 className="cta-heading mb-3">Get 14 Days FREE Trial</h1>
                <p className="paragraph-medium paragraph-medium-regular mb-3">
                  Unsure about our service? Try our Host Tier for free! New users get 2 weeks on us.
                </p>
                <a href="/signup">
                  <Btn
                    text="Start Your Free Trial Today"
                    class="heading-6 heading-6-bold secondary-btn mt-4"
                  />
                </a>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cta-img">
                <Image src={img1} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CTA;
