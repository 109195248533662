import { Image, Modal } from "react-bootstrap";

import closeBtn from "../../../assets/images/dashboard/close.svg";
import Btn from "../../templates/button";

import "../../../assets/scss/components/section/dashboard/cancelSubscriptionModal.scss";

const CustomCancelSubscriptioModal = (props) => {
  const handleCancelT1 = (e) => {
    e.preventDefault();
    window.location.replace(process.env.REACT_APP_STRIPE_PORTAL);
  };

  return (
    <div>
      <Modal
        className="subscription-cancel-modal"
        show={props.showmodal}
        onHide={props.closemodal}
      >
        <div className="">
          <div className="d-flex justify-content-end" closeButton>
            <Image
              src={closeBtn}
              onClick={props.closemodal}
              className="close-btn"
            />
          </div>
          <h3 className="modal-heading">Cancel Membership</h3>
          <div>
            <p className="modal-text">
              Are you sure you want to cancel your {props.modalTitle} <span>({props.modalPrice} per month)</span> membership? <br></br><br></br> Your subscription will remain active until the end of your current subscription period.
            </p>
            <Btn class="pricing-btn" text="Cancel Membership" onClick={handleCancelT1} />
            <p className="modal-text mt-4 mb-0"><strong>Please note that you will lose your dedicated phone number at the end of your subscription.</strong></p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomCancelSubscriptioModal;
