import { useContext, useState } from "react";
import { toast } from 'react-toastify';
import Turnstile, { useTurnstile } from "react-turnstile";

import AuthContext from "./AuthContext";

const TurnstileWidget = ({ onValueChange }) => {
    const { verifyCaptcha } = useContext(AuthContext);
    const turnstile = useTurnstile();

    const [hasError, setHasError] = useState(false);

    const notifyError = (errorMessage) => toast.error(errorMessage);

    const handleVerification = async (token) => {
        if (!token) {
            handleFailure("Invalid token.");
            return;
        }

        try {
            await verifyCaptcha({ token });
            onValueChange(true);
            setHasError(false);
        } catch (e) {
            handleFailure(e);
        }
    };

    const handleFailure = (error) => {
        setHasError(true);
        onValueChange(false);

        if (!error || error.code === "ERR_NETWORK") {
            notifyError("Something went wrong. Please try again later.");
        } else {
            const responseStatus = error.response?.status;

            if (responseStatus === 500) {
                notifyError("Something went wrong. Please try again later.");
            } else if (responseStatus === 400) {
                notifyError("Invalid request.");
            } else if (responseStatus === 408) {
                notifyError("Turnstile verification expired. Please try again.");
            } else {
                notifyError("Error validating Turnstile.");
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {!hasError && (
                <Turnstile
                    sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY}
                    onVerify={handleVerification}
                    theme="light"
                    className="d-flex justify-content-center mt-2"
                />
            )}

            {hasError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    Verification failed, please refresh and try again.
                </div>
            )}
        </div>
    );
};

export default TurnstileWidget;
