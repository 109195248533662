import { createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const loginApiCall = async (payload) => {
        const loginApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "user/login", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        if (loginApiResponse.data.status === "Fail") {
            ; // Do nothing!
        } else {
            const apiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user", {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': process.env.REACT_APP_API_KEY,
                }
            });

            if (apiResponse.data.status === "Fail") {
                ; // Do nothing!
            }
            else {
                if (apiResponse.data.verified_user) {
                    navigate("/dashboard");
                } else {
                    navigate("/email-verification");
                }
            }
        }
    };

    const createAccountApiCall = async (payload) => {
        const createAccountApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "user/create", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        if (createAccountApiResponse.data.status === "Fail") {
            ; // Do nothing!
        } else {
            const apiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user", {
                withCredentials: true,
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                }
            });

            if (apiResponse.data.status === "Fail") {
                ; // Do nothing
            }
            else {
                navigate("/email-verification");
            }
        }
    };

    const sendVerificationToken = async () => {
        const sendVerificationTokenApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user/authentication/token/send", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return sendVerificationTokenApiResponse;
    };

    const verifyToken = async (uuid) => {
        const verifyVerificationToken = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user/authentication/token/verify/" + uuid, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return verifyVerificationToken;
    };

    const isUserVerified = async () => {
        const isUserVerifiedApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user/status", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        if (!isUserVerifiedApiResponse.data.status)
            return false;

        return true;
    };

    const signOutUser = async () => {
        const signOutUserApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user/logout", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return signOutUserApiResponse;
    };

    const changePassword = async (payload) => {
        const changePasswordApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "password", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return changePasswordApiResponse;
    };

    const getUserDetails = async () => {
        const userDetails = await axios.get(process.env.REACT_APP_DOMAIN_URL + "user", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return userDetails;
    };

    const getDashboardDetails = async () => {
        const userDetails = await axios.get(process.env.REACT_APP_DOMAIN_URL + "dashboard/details", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return userDetails;
    };

    const getExistingProperties = async () => {
        const getPropertiesApiCall = await axios.get(process.env.REACT_APP_DOMAIN_URL + "property", {
            withCredentials: true,
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return getPropertiesApiCall;
    };

    const addProperty = async (payload) => {
        const addPropertyApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return addPropertyApiCall;
    };

    const editProperty = async (payload) => {
        const editPropertyApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/edit", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return editPropertyApiCall;
    }

    const getSingleProperty = async (payload) => {
        const getSinglePropertyApiCall = await axios.get(process.env.REACT_APP_DOMAIN_URL + "property/" + payload, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return getSinglePropertyApiCall;
    }

    const removeProperty = async (payload) => {
        const removePropertyApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/remove", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return removePropertyApiCall;
    };

    const callCreateCheckoutPlan = async (planName) => {
        const removePropertyApiCall = await axios.get(process.env.REACT_APP_DOMAIN_URL + "billing/v1/checkout/session/" + planName, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return removePropertyApiCall.data;
    };

    const submitMessage = async (payload) => {
        const submitMessageApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "user/message", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return submitMessageApiCall;
    };

    const sendVerificationCode = async (payload) => {
        const sendVerificationCodeApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "password/reset/verify", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return sendVerificationCodeApiCall;
    };

    const validateTemporaryCode = async (payload) => {
        const validateTemporaryCodeApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "password/reset/code", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return validateTemporaryCodeApiCall;
    };

    const resetPassword = async (payload) => {
        const resetPasswordApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "password/reset", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return resetPasswordApiCall;
    };

    const addTimelock = async (payload) => {
        const addTimelockApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/timelock", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return addTimelockApiCall;
    }

    const getTimelock = async (payload) => {
        const getTimelockApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/timelock/details", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return getTimelockApiCall;
    }

    const removeTimelock = async (payload) => {
        const removeTimelockApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/timelock/remove", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return removeTimelockApiCall;
    }


    const verifyCaptcha = async (payload) => {
        const verifyCaptchaApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "captcha/verify", payload, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return verifyCaptchaApiCall;
    }

    const getPaymentDetails = async (payload) => {
        const getPaymentDetailsApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "billing/v1/checkout/session", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return getPaymentDetailsApiCall;
    }

    const submitGeneralForm = async (payload) => {
        const submitForm = await axios.post(process.env.REACT_APP_DOMAIN_URL + "contact/form", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return submitForm;
    }

    const submitQuoteRequest = async (payload) => {
        const submitForm = await axios.post(process.env.REACT_APP_DOMAIN_URL + "contact/quote", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return submitForm;
    }

    const disableGateteck = async (payload) => {
        const disableGateteckApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "property/disable", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_KEY,
            }
        });

        return disableGateteckApiCall;
    }

    return <AuthContext.Provider value={{
        loginApiCall, createAccountApiCall, sendVerificationToken, verifyToken, isUserVerified,
        signOutUser, changePassword, getUserDetails, getDashboardDetails, getExistingProperties,
        addProperty, editProperty, getSingleProperty, removeProperty, callCreateCheckoutPlan, submitMessage, sendVerificationCode, validateTemporaryCode,
        resetPassword, addTimelock, getTimelock, removeTimelock, verifyCaptcha, getPaymentDetails, submitGeneralForm, submitQuoteRequest, disableGateteck
    }}>
        {children}
    </AuthContext.Provider>
}


export default AuthContext;
