import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../assets/scss/common.scss";
import "../assets/scss/pages/signup.scss";
import 'react-toastify/dist/ReactToastify.css';

import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

import back from "../assets/images/back.svg";
import logo from "../assets/images/black-font-logo.svg";
import img1 from "../assets/images/signup-img.svg";

import { useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';

import check from "../assets/images/checkbox-check.svg";
import TurnstileWidget from "../components/shared/TurnstileWidget";

const SignUp = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [isAgeChecked, setIsAgeChecked] = useState(false);
  const [createAccountFields, setCreateAccountFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { createAccountApiCall } = useContext(AuthContext);

  const notifyError = (errorMessage) => toast.error(errorMessage);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAgeCheckboxChange = () => {
    setIsAgeChecked(!isAgeChecked);
  };

  const goBack = () => {
    navigate("/");
  };

  const createAccountChange = (field, value) => {
    setCreateAccountFields({
      ...createAccountFields,
      [field]: value
    });
  };

  const passwordChange = (field, value) => {
    setCreateAccountFields({
      ...createAccountFields,
      [field]: value
    });

    if (value === "")
      setErrorText("");
    else
      setErrorText(handlePasswordValidation(value));
  };

  const handlePasswordValidation = (password) => {
    var capitalLetterRegex = /[A-Z]/;
    var specialCharacterRegexPwd = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (password.length === 0)
      return "";
    else if (password.length < 8)
      return "Password should be at least 8 characters long.";
    else if (!capitalLetterRegex.test(password))
      return "Password should contain at least 1 capital letter.";
    else if (!specialCharacterRegexPwd.test(password))
      return "Password should contain at least 1 special character.";
    else
      return "";
  }

  const handleCreateAccountSubmit = async (e) => {
    const formFields = { ...createAccountFields };

    try {
      e.preventDefault();

      if (handleCreateAccountValidation()) {

        const firstName = formFields['first-name'];
        const lastName = formFields['last-name'];
        const email = formFields['email'];
        const password = formFields['password'];
        const confirmPassword = formFields['confirm-password'];
        const userPhone = formFields['phone'];

        const payload = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          confirm_password: confirmPassword,
          captcha_verified: isCaptchaVerified,
          user_phone: userPhone,
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);

        document.body.classList.add('disable-scroll');

        await createAccountApiCall(payload);
      }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
        notifyError("Website is under maintenance. Please try again later.");
      } else {
        const responseStatus = e.response?.status;
        const responseMessage = e.response?.data?.message;

        if (responseStatus === 401) {
          notifyError("Unauthorized. Please try again.");
        } else if (responseStatus === 409) {
          notifyError(responseMessage ? responseMessage : "Something went wrong. Please try again later.");
        } else {
          notifyError("Something went wrong. Please try again later.");
        }
      }
    } finally {
      setLoading(false);
      setDisableButton(true);
      setDisableBackButton(false);

      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
      }, 2000);
    }
  }

  const handleCreateAccountValidation = () => {
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]{0,50}$/;
    var specialCharacterRegexEmail = /[!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    var specialCharacterRegexPwd = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    var capitalLetterRegex = /[A-Z]/;
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var phoneRegex = /^[0-9][0-9]{2}-[0-9][0-9]{2}-[0-9]{4}$/;
    var phoneRegexNoDashes = /^\d{10}$/;

    const formFields = { ...createAccountFields };
    let formIsValid = true;

    if (!formFields['first-name']) {
      formIsValid = false;
      notifyError("First name can't be empty.");
    } else if (!nameRegex.test(formFields['first-name'])) {
      formIsValid = false;
      notifyError("First name has invalid characters.");
    } else if (formFields['first-name'].length > 40) {
      formIsValid = false;
      notifyError("First name is too long.");
    } else if (!formFields['last-name']) {
      formIsValid = false;
      notifyError("Last name can't be empty.");
    } else if (!nameRegex.test(formFields['last-name'])) {
      formIsValid = false;
      notifyError("Last name has invalid characters.");
    } else if (formFields['last-name'].length > 40) {
      formIsValid = false;
      notifyError("Last name is too long.");
    } else if (!formFields['email']) {
      formIsValid = false;
      notifyError("Email can't be empty.");
    } else if (specialCharacterRegexEmail.test(formFields['email'])) {
      formIsValid = false;
      notifyError("Email has invalid characters.");
    } else if (formFields['email'].length > 320) {
      formIsValid = false;
      notifyError("Email is too long.");
    } else if (!emailRegex.test(formFields['email'])) {
      formIsValid = false;
      notifyError("Email is invalid.");
    } else if (!formFields['phone']) {
      formIsValid = false;
      notifyError("Phone number can't be empty.");
    } else if (!phoneRegex.test(formFields['phone']) && !phoneRegexNoDashes.test(formFields['phone'])) {
      formIsValid = false;
      notifyError("Phone number is invalid.");
    } else if (formFields['phone'].length > 12) {
      formIsValid = false;
      notifyError("Phone number is too long.");
    } else if (!formFields['password'] || !formFields['confirm-password']) {
      formIsValid = false;
      notifyError("Password can't be empty.");
    } else if (formFields['password'].length < 8) {
      formIsValid = false;
      notifyError("Password must be at least 8 characters long.");
    } else if (formFields['password'].length > 300) {
      formIsValid = false;
      notifyError("Password is too long.");
    } else if (!capitalLetterRegex.test(formFields["password"])) {
      formIsValid = false;
      notifyError("Password must have at least 1 capital letter.");
    } else if (!specialCharacterRegexPwd.test(formFields["password"])) {
      formIsValid = false;
      notifyError("Password must have at least 1 special character. (!@#$%^&*)");
    } else if (formFields['confirm-password'] && (formFields['password'] != formFields['confirm-password'])) {
      formIsValid = false;
      notifyError("Passwords do not match.");
    } else if (!isChecked) {
      formIsValid = false;
      notifyError("You have to agree to our terms and conditions.");
    } else if (!isAgeChecked) {
      formIsValid = false;
      notifyError("You have to be 18 years or older to sign up.");
    } else if (!isCaptchaVerified) {
      formIsValid = false;
      notifyError("Captcha not validated. Please try again.");
    }

    return formIsValid;
  };

  const handleTurnstileChange = (value) => {
    setIsCaptchaVerified(value);
  }

  return (
    <div className="signup-box">
      <div className="signup-login-common">
        <Row className="align-items-center ">
          <Col lg={6}>
            <div className="signup-content">
              <div className="back-btn p-lg-0 pt-4 pb-2">
                <Button className="back-btn" onClick={goBack} disabled={disableBackButton}>
                  <div className="back-icon me-2">
                    <Image src={back} />
                  </div>
                  Back
                </Button>
              </div>
              <div className="d-flex flex-column text-center p-lg-5 p-3 py-0 pt-2">
                <div className="logo mb-3">
                  <Image src={logo} width="225px" />
                </div>
                <h2 className="heading-2 heading-2-semi-bold d-none d-lg-block">
                  Save time with GateTeck
                </h2>
                <div className="img-1 my-4 text-center">
                  <Image src={img1} className="img-fluid" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="d-flex justify-content-center pe-lg-5">
              <div className="form px-2">
                <h4 className="heading-4 heading-4-semi-bold text-center mb-4">
                  Sign Up to Get Started
                </h4>
                <div>
                  <Form onSubmit={handleCreateAccountSubmit}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="First Name" onChange={e => createAccountChange('first-name', e.target.value)} value={createAccountFields['first-name']} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Last Name" onChange={e => createAccountChange('last-name', e.target.value)} value={createAccountFields['last-name']} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Control type="email" placeholder="Email Address" onChange={e => createAccountChange('email', e.target.value)} value={createAccountFields['email']} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="phone" placeholder="Phone Number" onChange={e => createAccountChange('phone', e.target.value)} value={createAccountFields['phone']} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="password" placeholder="Password" onChange={e => passwordChange('password', e.target.value)} value={createAccountFields['password']} />
                      {errorText && <p className="paragraph-small paragraph-small-regular text-center error-text my-2">{errorText}</p>}
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        onChange={e => createAccountChange('confirm-password', e.target.value)} value={createAccountFields['confirm-password']}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <TurnstileWidget onValueChange={handleTurnstileChange}></TurnstileWidget>
                    </Form.Group>
                    <div className="checkbox">
                      <div className="custom-checkbox-container">
                        <input
                          type="checkbox"
                          id="customCheckbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="hidden-checkbox"
                        />
                        <label
                          htmlFor="customCheckbox"
                          className={`custom-checkbox ${isChecked ? "checked" : ""
                            }`}
                        >
                          {isChecked && (
                            <span className="checkmark">
                              <Image src={check} />
                            </span>
                          )}
                        </label>
                      </div>
                      <p className="paragraph-small paragraph-small-regular text-start signup-text">
                        By clicking Sign Up, you agree to our <a href="https://cdn.gateteck.com/gateteck_tos.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>, &nbsp;
                        <a href="https://cdn.gateteck.com/gateteck_disclaimer.pdf" target="_blank" rel="noopener noreferrer">Disclaimers</a>, &nbsp;
                        <a href="https://cdn.gateteck.com/gateteck-privacy_policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and &nbsp;
                        <a href="https://cdn.gateteck.com/gateteck-cookie_policy.pdf" target="_blank" rel="noopener noreferrer">Cookies Policy</a>. You may receive email and/or
                        SMS Notifications from us and can opt out anytime.
                      </p>
                    </div>
                    <div className="checkbox">
                      <div className="custom-checkbox-container">
                        <input
                          type="checkbox"
                          id="ageCheckbox"
                          checked={isAgeChecked}
                          onChange={handleAgeCheckboxChange}
                          className="hidden-checkbox"
                        />
                        <label
                          htmlFor="ageCheckbox"
                          className={`custom-checkbox ${isAgeChecked ? "checked" : ""
                            }`}
                        >
                          {isAgeChecked && (
                            <span className="checkmark">
                              <Image src={check} />
                            </span>
                          )}
                        </label>
                      </div>
                      <p className="paragraph-small paragraph-small-regular text-start signup-text">
                        By clicking Sign Up, you agree that you are 18 years or older.
                      </p>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      className="primary-btn mb-3"
                      disabled={disableButton}
                    >
                      Sign Up
                    </Button>
                  </Form>
                </div>
                <p className="paragraph-sub paragraph-main-regular text-center mt-3">
                  Already Have Account?
                  <a
                    href="/login"
                    className="link ms-2"
                    style={{ color: "#000" }}
                    disabled={disableButton}
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ToastContainer />
      {loading && <Loading />}
    </div>
  );
};

export default SignUp;
