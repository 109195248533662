import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";

const ProtectedRoutes = ({ children, accessBy }) => {
    const { isUserVerified } = useContext(AuthContext);
    const [isVerified, setIsVerified] = useState(false);
    const [isNonVerified, setIsNonVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function checkUserVerification() {
            try {
                setIsLoading(true)
                const verified = await isUserVerified();
                setIsLoading(false);
                setIsVerified(verified);

                if (isVerified) {
                    if (accessBy === "auth-verified") {
                        return children;
                    } else {
                        navigate("/dashboard");
                    }
                }
            } catch (e) {
                if (e === null || e.code === "ERR_NETWORK") {
                    if (accessBy === "auth-nonverified" || accessBy === "auth-verified")
                        navigate("/");
                    else
                        return children;
                } else {
                    let responseCode = e?.response?.status;
                    if (responseCode === 403) {
                        setIsNonVerified(true);
                        if (accessBy === "auth-nonverified") {
                            return children;
                        } else {
                            navigate("/email-verification");
                        }
                    } else {
                        if (accessBy !== "non-auth") {
                            setIsNonVerified(false);
                            setIsVerified(false);
                            navigate("/");
                        }
                    }
                }
            } finally {
                setIsLoading(false);
            }
        }

        checkUserVerification();

    }, [isUserVerified]);

    if (!isLoading) {
        if (accessBy === "non-auth") {
            if (isVerified) {
                navigate("/dashboard");
            } else if (isNonVerified) {
                navigate("/email-verification");
            } else {
                return children;
            }
        } else if (accessBy === "auth-nonverified") {
            if (isVerified) {
                navigate("/dashboard");
            } else if (isNonVerified) {
                return children;
            } else {
                navigate("/");
            }
        } else if (accessBy === "auth-verified") {
            if (isVerified) {
                return children;
            } else if (isNonVerified) {
                navigate("/email-verification");
            } else {
                navigate("/");
            }
        }
    }

};

export default ProtectedRoutes;
