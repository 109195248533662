import { Row, Col, Container, Image, Form } from "react-bootstrap";
import { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';

import AuthContext from "../components/shared/AuthContext";
import NavbarForm from "../components/templates/navbarForm";
import CustomInput from "../components/templates/input";
import Loading from "../components/shared/Loading";
import TurnstileWidget from "../components/shared/TurnstileWidget";
import Btn from "../components/templates/button";

import shape1 from "../assets/images/shape-1.svg";
import check from "../assets/images/checkbox-check.svg";

import "../assets/scss/pages/letsTalk.scss"
import "../assets/scss/pages/contactUs.scss";
import "../assets/scss/common.scss";

const RequestQuote = () => {
    const { submitQuoteRequest } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [messageField, setMessageField] = useState({});
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isOptChecked, setIsOptChecked] = useState(false);
    const [disableFields, setDisableFields] = useState(false);

    const sources = [
        { id: "google search", label: "Google Search" },
        { id: "blog", label: "Blog" },
        { id: "linkedin", label: "LinkedIn" },
        { id: "facebook", label: "Facebook" },
        { id: "instagram", label: "Instagram" },
        { id: "x", label: "X" },
        { id: "other", label: "Other" },
    ];

    const notifyError = (errorMessage) => toast.error(errorMessage);
    const notifySuccess = (successMessage) => toast.success(successMessage);

    const handleMessageSubmit = async (e) => {
        const formField = { ...messageField };

        try {
            e.preventDefault();

            if (handleMessageValidation()) {
                const inputName = formField['name'];
                const inputCompanyName = formField['company-name'];
                const inputEmail = formField['email'];
                const inputPropertyCount = formField['count'];
                let inputSource = formField['source'];
                const inputMsg = formField['message'];

                if (!inputSource) {
                    inputSource = "Google Search";
                }

                const payload = {
                    name: inputName,
                    company_name: inputCompanyName,
                    email: inputEmail,
                    count: inputPropertyCount,
                    source: inputSource,
                    message: inputMsg,
                    is_captcha_verified: isCaptchaVerified,
                    is_opted_in: isOptChecked,
                };

                setLoading(true);
                setDisableButton(true);
                document.body.classList.add('disable-scroll');

                await submitQuoteRequest(payload);

                notifySuccess("Message successfuly sent!");
                setDisableFields(true);
                setIsCaptchaVerified(false);
            }
        } catch (e) {
            if (e === null || e.code === "ERR_NETWORK") {
                notifyError("Something went wrong. Please try again later.");
            } else {
                const responseStatus = e.response?.status;

                if (responseStatus === 401) {
                    notifyError("Unauthorized. Please try again.");
                } else if (responseStatus === 413) {
                    notifyError("Your message is too large.");
                } else if (responseStatus === 429) {
                    notifyError("Message limit reached. Please try again later.");
                } else if (responseStatus === 403) {
                    notifyError("Turnstile is not validated. Please comlpete verification before a sending message.");
                } else {
                    notifyError("Something went wrong. Please try again later.");
                }
            }
        } finally {
            setLoading(false);
            setDisableButton(true);
            document.body.classList.remove('disable-scroll');

            setTimeout(() => {
                setDisableButton(false);
            }, 2000);
        }
    }

    const handleMessageValidation = () => {
        const messageRegex = /^[A-Za-z0-9 .,?!'"():;_@$%#\n-]{0,1000}$/;
        const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]{0,50}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const upToFiveDigitRegex = /^\d{1,5}$/;


        const formFields = { ...messageField };

        const inputName = formFields['name'];
        const inputCompanyName = formFields['company-name'];
        const inputEmail = formFields['email'];
        const inputPropertyCount = formFields['count'];
        let inputSource = formFields['source'];
        const inputMsg = formFields['message'];

        let formIsValid = true;

        if (!inputSource) {
            inputSource = "Google Search";
        }

        if (!inputName) {
            formIsValid = false;
            notifyError("Name can't be empty.");
        } else if (!nameRegex.test(inputName)) {
            formIsValid = false;
            notifyError("Name has invalid characters.");
        } else if (inputName.length > 50) {
            formIsValid = false;
            notifyError("Name is too long.");
        } else if (inputName.length < 2) {
            formIsValid = false;
            notifyError("Name is too short.");
        } else if (!inputCompanyName) {
            formIsValid = false;
            notifyError("Company name can't be empty.");
        } else if (!nameRegex.test(inputCompanyName)) {
            formIsValid = false;
            notifyError("Company name has invalid characters.");
        } else if (inputCompanyName.length > 50) {
            formIsValid = false;
            notifyError("Company name is too long.");
        } else if (inputCompanyName.length < 2) {
            formIsValid = false;
            notifyError("Company name is too short.");
        } else if (!inputEmail) {
            formIsValid = false;
            notifyError("Email can't be empty");
        } else if (!emailRegex.test(inputEmail)) {
            formIsValid = false;
            notifyError("Email is invalid.");
        } else if (inputEmail.length > 320) {
            formIsValid = false;
            notifyError("Email is too long.");
        } else if (inputEmail.length < 5) {
            formIsValid = false;
            notifyError("Email is too short.");
        } else if (!inputPropertyCount) {
            formIsValid = false;
            notifyError("Property count can't be empty.");
        } else if (!inputPropertyCount) {
            formIsValid = false;
            notifyError("Property count can't be empty.");
        } else if (!upToFiveDigitRegex.test(inputPropertyCount)) {
            formIsValid = false;
            notifyError("Property count is invalid. Please type up to 5 digits.");
        } else if (!inputSource) {
            formIsValid = false;
            notifyError("Please select a referral source.");
        } else if (!inputMsg) {
            formIsValid = false;
            notifyError("Message can't be empty.");
        } else if (!messageRegex.test(inputMsg)) {
            formIsValid = false;
            notifyError("Message has invalid characters.");
        } else if (inputMsg.length > 500) {
            formIsValid = false;
            notifyError("Message is too long. Please use less than 500 characters.");
        } else if (inputMsg.length < 10) {
            formIsValid = false;
            notifyError("Message is too short. Please use more than 10 characters.");
        } else if (!isCaptchaVerified) {
            formIsValid = false;
            notifyError("Turnstile not validated. Please try again.");
        } else if (!isChecked) {
            formIsValid = false;
            notifyError("You must agree to our Disclaimers and Privacy Policy.");
        }

        return formIsValid;
    };

    const messageChange = (field, value) => {
        setMessageField({
            ...messageField,
            [field]: value
        });
    };

    const handleTurnstileChange = (value) => {
        setIsCaptchaVerified(value);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleOptChange = () => {
        setIsOptChecked(!isOptChecked);
    };

    return (
        <div className="hero-section" id="quote">
            <Container>
                <div className="position-relative">
                    <NavbarForm />
                    <Row className="hero-content">
                        <Col className="text-center">
                            <div className="hero-heading">
                                <h1 className="heading-1 heading-1-semi-bold mx-auto">
                                    Request a Quote
                                </h1>
                                <p className="paragraph-medium paragraph-medium-regular mx-auto">
                                    For custom plans please submit the form below.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="shape-1 shape z-0">
                        <Image src={shape1} />
                    </div>
                </div>
            </Container>
            <div className="contact text-center">
                {/* <Image src={img2} className="img-fluid" /> */}
                <div className="contact-box-c">
                    <div className="contact-box mb-5">
                        <Form onSubmit={handleMessageSubmit}>
                            <Row className="pt-5 contact-row">
                                <CustomInput
                                    label="Name"
                                    placeholder="Name"
                                    type="text"
                                    labelClassName="form-custom-label"
                                    onChange={e => messageChange('name', e.target.value)} value={messageField['name']}
                                    disabled={disableFields}
                                />
                                <CustomInput
                                    label="Company Name"
                                    placeholder="Company Name"
                                    type="text"
                                    labelClassName="form-custom-label"
                                    onChange={e => messageChange('company-name', e.target.value)} value={messageField['company-name']}
                                    disabled={disableFields}
                                />
                                <CustomInput
                                    label="Company Email"
                                    placeholder="Email"
                                    type="email"
                                    labelClassName="form-custom-label"
                                    onChange={e => messageChange('email', e.target.value)} value={messageField['email']}
                                    disabled={disableFields}
                                />
                                <CustomInput
                                    label="Desired Number of Properties"
                                    subtext="How many properties do you need us to support?"
                                    placeholder="e.g. 23"
                                    type="text"
                                    labelClassName="form-custom-label"
                                    onChange={e => messageChange('count', e.target.value)} value={messageField['count']}
                                    disabled={disableFields}
                                />
                                <CustomInput
                                    label="How Did You Hear About Us?"
                                    placeholder="Select a Source"
                                    labelClassName="form-custom-label"
                                    className="w-100 text-center"
                                    type="dropdown"
                                    onChange={e => messageChange('source', e.target.value)}
                                    value={messageField['source'] || 'googlesearch'}
                                    disabled={disableFields}
                                >
                                    {sources.map(source => (
                                        <option key={source.id} value={source.id}>
                                            {source.label}
                                        </option>
                                    ))
                                    }
                                </CustomInput>
                            </Row>
                            <div className="message-textarea mb-4">
                                <h6 className="heading">Message</h6>
                                <p className="custom-subtext">Any questions or looking for a specific feature?</p>
                                <textarea
                                    placeholder="Let us know"
                                    className="textarea"
                                    onChange={e => messageChange('message', e.target.value)}
                                    value={messageField['message']}
                                    disabled={disableFields}
                                ></textarea>
                            </div>
                            <TurnstileWidget onValueChange={handleTurnstileChange}></TurnstileWidget>
                            <div className="submit-btn">
                                <div>
                                    {!disableFields && <Btn text="Request Quote" class="primary-btn" type="submit" disableButton={disableButton || disableFields} />}
                                    {disableFields && <p className="text-success fw-bold resp-txt">Thanks! Someone from our team will reach out soon.</p>}
                                </div>
                            </div>
                            <Row className="w-100">
                                <div className="checkbox">
                                    <div className="custom-checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="customCheckbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            className="hidden-checkbox"
                                            disabled={disableFields}
                                        />
                                        <label
                                            htmlFor="customCheckbox"
                                            className={`custom-checkbox ${isChecked ? "checked" : ""}`}
                                        >
                                            {isChecked && (
                                                <span className="checkmark">
                                                    <Image src={check} />
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    <p className="paragraph-small paragraph-small-regular text-start signup-text">
                                        <span className="asterisk">*</span> Click here to agree to our&nbsp;
                                        <a href="https://cdn.gateteck.com/gateteck_disclaimer.pdf" target="_blank" rel="noopener noreferrer">Disclaimers</a> and&nbsp;
                                        <a href="https://cdn.gateteck.com/gateteck-privacy_policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                    </p>
                                </div>
                                <div className="checkbox" id="last-checkbox">
                                    <div className="custom-checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="optCheckbox"
                                            checked={isOptChecked}
                                            onChange={handleOptChange}
                                            className="hidden-checkbox"
                                            disabled={disableFields}
                                        />
                                        <label
                                            htmlFor="optCheckbox"
                                            className={`custom-checkbox ${isOptChecked ? "checked" : ""
                                                }`}
                                        >
                                            {isOptChecked && (
                                                <span className="checkmark">
                                                    <Image src={check} />
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    <p className="paragraph-small paragraph-small-regular text-start signup-text">
                                        I would like to receive occasional updates, feature releases, and promotions from GateTeck. You may opt out at any time.
                                    </p>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loading></Loading>}
        </div>
    );
};

export default RequestQuote;
