import { useNavigate } from "react-router-dom";
import { Image, Container, Nav, Navbar } from "react-bootstrap";

import logo from "../../assets/images/logo.svg";
import menu from "../../assets/images/menu.svg";
import close from "../../assets/images/close.svg";

import Btn from "./button";
import CustomToggler from "./customToggler";
import { useState } from "react";

function NavbarHome() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const goToSignup = () => {
    navigate("/signup");
  };

  const gotToLogin = () => {
    navigate("/login");
  };

  const goToForm = () => {
    navigate("/form");
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLinkClick = (e, target) => {
    e.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Navbar expand="lg" expanded={open} className="pt-3 position-relative z-2">
      <Container fluid>
        <Navbar.Brand href="/">
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle
          as={CustomToggler}
          iconClass={open ? close : menu}
          clas={open ? "closeIcon" : "menuIcon"}
          onClick={handleToggle}
        />
        <Navbar.Collapse>
          <Nav className="mx-auto my-2 my-lg-0">
            <Nav.Link onClick={(e) => handleLinkClick(e, '#features')}>Features</Nav.Link>
            <Nav.Link onClick={(e) => handleLinkClick(e, '#how-it-works')}>How it Works</Nav.Link>
            <Nav.Link onClick={(e) => handleLinkClick(e, '#pricing')}>Pricing</Nav.Link>
            <Nav.Link onClick={() => goToForm()}>Contact Us</Nav.Link>
          </Nav>
          <div className="navbar-btn">
            <Btn
              text="Login"
              class="me-2 transparent-btn"
              onClick={gotToLogin}
            />
            <Btn text="Get Started" class="outline-btn" onClick={goToSignup} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarHome;
