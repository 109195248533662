import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import logo from "../../assets/images/footer-logo.svg";
import insg from "../../assets/images/in.svg";
import tw from "../../assets/images/tw.svg";

export default function Footer() {

  const handleLinkClick = (e, target) => {
    e.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="footer-section">
      <Container>
        <Row>
          <div className="d-flex align-items-center justify-content-lg-between justify-content-center mb-5">
            <div className="footer-logo">
              <Image src={logo} />
            </div>
            <div className="desktop-social-links social-links d-flex align-items-center justify-content-lg-start justify-content-center mb-4 mb-lg-0 me-5">
              <a href="https://www.instagram.com/gateteck/" className="social me-3">
                <Image src={insg} />
              </a>
              <a href="https://x.com/gateteck" className="social me-3">
                <Image src={tw} />
              </a>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={3} xs={6}>
            <div className="mb-5 mb-lg-0">
              <h5 className="heading-5 heading-5-bold mb-4 mb-2">
                Quick Links
              </h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="#features" onClick={(e) => handleLinkClick(e, '#features')} className="footer-link">
                    Features
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#how-it-works" onClick={(e) => handleLinkClick(e, '#how-it-works')} className="footer-link">
                    How it works
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#pricing" onClick={(e) => handleLinkClick(e, '#pricing')} className="footer-link">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} xs={6}>
            <div className="mb-5 mb-lg-0">
              <h5
                className="heading-5 heading-5-bold mb-4 mb-2"
              >
                Contact
              </h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="/form" className="footer-link">
                    Contact Us
                  </a>
                </li>
                <a href="/request-a-quote" className="footer-link">
                  Request a Quote
                </a>
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-5 mb-lg-0">
              <h5 className="heading-5 heading-5-bold mb-4 mb-2">Legal</h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="https://cdn.gateteck.com/gateteck_tos.pdf" className="footer-link">
                    Terms of Service
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://cdn.gateteck.com/gateteck_disclaimer.pdf" className="footer-link">
                    Disclaimers
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://cdn.gateteck.com/gateteck-privacy_policy.pdf" className="footer-link">
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://cdn.gateteck.com/gateteck-cookie_policy.pdf" className="footer-link">
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-2 mb-lg-0">
              <div className="mobile-social-links social-links d-flex align-items-center justify-content-lg-start justify-content-center mb-4 mb-lg-0">
                <a href="https://www.instagram.com/gateteck/" className="social me-3">
                  <Image src={insg} />
                </a>
                <a href="https://x.com/gateteck" className="social me-3">
                  <Image src={tw} />
                </a>
              </div>
              <p className="paragraph-main paragraph-main-regular text-lg-end text-center">
                © GateTeck Solutions, LLC
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
