import "../assets/scss/common.scss";
import "../assets/scss/pages/paymentSuccess.scss";

import logo from "../assets/images/dashboard/payment-success-logo.svg";
import check from "../assets/images/dashboard/check.svg";

import Btn from "../components/templates/button";
import Topbar from "../components/sections/dashboard/topbar"
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

import { useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Image } from "react-bootstrap";

const PaymentSuccess = () => {
  const { getPaymentDetails } = useContext(AuthContext);

  const [userFirstName, setUserFirstName] = useState(null);
  const [plan, setPlan] = useState(null);
  const [renewal, setRenewal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [isFreeTrial, setIsFreeTrial] = useState("no");
  const [loading, setLoading] = useState(false);

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const navigate = useNavigate();

  useEffect(() => {
    async function getDetails(sid, cid) {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const payload = {
          session_id: sid,
          token: cid
        }

        const details = await getPaymentDetails(payload);

        const firstName = details?.data?.first_name;
        const planName = details?.data?.plan_name;
        const txn = details?.data?.amount;
        const renewalDate = details?.data?.renewal_date;
        const confirmationCode = details?.data?.active_plan_id;
        const isFreeTrialFlag = details?.data?.stripe_is_free_trial;

        setUserFirstName(firstName);
        setPlan(planName);
        setAmount(txn);
        setRenewal(renewalDate);
        setConfirmation(confirmationCode);
        setIsFreeTrial(isFreeTrialFlag);
      } catch (e) {
        if (e === null || e.code === "ERR_NETWORK") {
          notifyError("Something went wrong. Please try again later.");
        } else {
          const responseStatus = e.response?.status;

          if (responseStatus === 401) {
            notifyError("User is not authorized.");
            navigate('/');
          } else if (responseStatus === 406) {
            notifyError("Session is expired.");
            navigate('/home');
          } else if (responseStatus === 404) {
            notifyError("Unable to find checkout session.");
            navigate('/home');
          } else {
            notifyError("Something went wrong. Please try again later.");
            navigate('/home');
          }
        }
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const sid = urlParams.get('sid');
    const token = urlParams.get('cid');

    if (!sid || !token || sid.length === 0 || token.length === 0) {
      navigate('/home');
    } else {
      getDetails(sid, token);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.location.replace("https://gateteck.com/home");
  }

  if (loading)
    return <Loading></Loading>;

  return (
    <div className="w-100 pay-confirm">
      <Topbar type="verification" firstName={userFirstName} />
      <div className="dashboard-body pb-5">
        <div className="subscription-verification-common mt-5">
          <div className="logo">
            <Image src={logo} />
          </div>
          <div className="success-verification-box success-box">
            <div className="check-icon">
              <div className="icon">
                <Image src={check} />
              </div>
            </div>
            <h3 className="heading-3 heading-3-semi-bold"> {isFreeTrial === "yes" ? "Free Trial Activated" : "Payment Successful"}</h3>
            <p className="paragraph-main paragraph-main-regular">
              {isFreeTrial === "yes" ? "Thank you for starting your free trial! We have successfully upgraded your account." : "Thank you for becoming a paid member! We have successfully processed your payment."}

            </p>
            <h5 className="paragraph-type text-start mt-4">
              Confirmation Code:
            </h5>
            <p className="paragraph-val mt-3">
              GT-{confirmation}
            </p>
            <h5 className="paragraph-type text-start mt-1">
              Active Plan:
            </h5>
            <p className="paragraph-val mt-3">
              {plan}
            </p>
            <h5 className="paragraph-type text-start mt-1">
              Renews On:
            </h5>
            <p className="paragraph-val mt-3">
              {renewal}
            </p>
            <h5 className="paragraph-type text-start mt-1">
              Amount Processed:
            </h5>
            <p className="paragraph-val mt-3">
              {amount}
            </p>
            <p className="paragraph-main paragraph-main-regular mb-4 mt-4">
              A phone number has been generated for you and can be viewed using the
              button below.
            </p>
            <Btn class="primary-btn" text="View Phone Number" onClick={handleSubmit} />
            <p className="paragraph-main paragraph-main-regular mb-4 mt-3">
              {isFreeTrial === "yes" ? "Please note that your subscription will automatically renew into a paid plan at the end of your trial period unless cancelled." : ""}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentSuccess;
