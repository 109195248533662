import { useState, useEffect, useContext } from "react";
import "../assets/scss/common.scss";
import "../assets/scss/pages/dashboard.scss";
import { Col, Image, Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';

import totalProperties from "../assets/images/dashboard/total-properties.svg";
import totalVisits from "../assets/images/dashboard/total-visits.svg";
import phone from "../assets/images/dashboard/phone.svg";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const Dashboard = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [memberSinceDate, setMemberSinceDate] = useState(null);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState(null);
  const [totalVisitsCount, setTotalVisitsCount] = useState(null);
  const [totalDedicatedPhoneCount, setTotalDedicatedPhoneCount] = useState(null);
  const [planId, setPlanId] = useState("--");

  const { getUserDetails, getDashboardDetails } = useContext(AuthContext);

  const notifyError = (errorMessage) => toast.error(errorMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const details = await getUserDetails();
        const dashboardDetails = await getDashboardDetails();

        setUserFirstName(details?.data?.first_name);
        setCurrentPlan(details?.data?.plan_name);
        setUserPhone(details?.data?.rented_phone);
        setMemberSinceDate(details?.data?.member_since_date);
        setPlanId(details?.data?.plan_id);

        setTotalPropertiesCount(dashboardDetails?.data?.total_properties);
        setTotalVisitsCount(dashboardDetails?.data?.total_visits);
        setTotalDedicatedPhoneCount(dashboardDetails?.data?.total_dedicated_phone_numbers);

      } catch (error) {
        notifyError("Something went wrong. Please try again later.");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();
  }, []);

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const statsData = [
    {
      text: "Total Properties",
      count: totalPropertiesCount ? totalPropertiesCount + "" : "0",
      img: totalProperties,
      class: "totalProperties",
    },
    {
      text: "Total Visits",
      count: totalVisitsCount ? totalVisitsCount + "" : "0",
      img: totalVisits,
      class: "totalVisits",
    },
    {
      text: "Dedicated Phone Numbers",
      count: totalDedicatedPhoneCount ? totalDedicatedPhoneCount + "" : "0",
      img: phone,
      class: "phone",
    },
  ];

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="dashboard"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName} />
          <div className="dashboard-content">
            <h5 className="heading-4 heading-4-semi-bold">Welcome {userFirstName ? userFirstName : ""}!</h5>
            <div className="properties-stats">
              <h5 className="heading-5 heading-5-semi-bold">Stats</h5>
              <Row className="justify-content-center">
                {statsData.map((stat) => (
                  <Col md={4} sm={6}>
                    <div className={`stats ${stat.class}`}>
                      <div className="stats-text">
                        <p className="text">{stat.text}</p>
                      </div>
                      <div className="stats-image">
                        <h2 className="count heading-1 heading-1-semi-bold">
                          {stat.count}
                        </h2>
                        <div className="img">
                          <Image src={stat.img} />
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="membership-detail">
              <h5 className="heading-5 membership-detail-heading heading-5-semi-bold">
                Membership Details
              </h5>
              <div className="membership-detail-box">
                <div className="membership-detail-content">
                  <div className="tier-detail">
                    <h5 className="heading-5 heading-5-semi-bold">
                      {currentPlan ? currentPlan : "Free "} Tier
                    </h5>
                    <p className="member-since">Plan ID: {planId}</p>
                    <p className="member-since">Account created on {memberSinceDate ? memberSinceDate : "--"}</p>
                  </div>
                  <div>
                    <p className="contact-no">{userPhone ? userPhone : "--"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading></Loading>}
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
