import { Form } from "react-bootstrap";
import "../../assets/scss/components/templates/_input.scss";

const CustomInput = (props) => {
  return (
    <div className="custom-input">
      <Form.Label className={props.labelClassName}>{props.label}</Form.Label>
      {props.subtext && <p className="custom-subtext">{props.subtext}</p>}
      {props.type === "dropdown" ? (
        <Form.Select
          className={props.className ? props.className : "mb-3"}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disabled}
        >
          <option value="" disabled>{props.placeholder}</option>
          {props.children}
        </Form.Select>
      )
        : (<Form.Control
          type={props.type}
          placeholder={props.placeholder}
          className={props.className ? props.className : "mb-3"}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disabled}
        />)}
    </div>
  );
};

export default CustomInput;
