import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation  } from "react-router-dom";

import "../assets/scss/common.scss";
import "../assets/scss/pages/addTimelock.scss";
import "../assets/scss/pages/signup.scss";
import "../assets/scss/pages/addProperty.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import GoBackButton from "../components/templates/goBackBtn";
import CustomInput from "../components/templates/input";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const EditProperty = ({ userFirstName, planName }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [updateProperty, setUpdateProperty] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [digits, setDigits] = useState("");
  const [timezone, setTimezone] = useState("");
  const [propertyType, setPropertyType] = useState();
  const [propertyCallbox, setPropertyCallbox] = useState("");
  const [editPropertyFields, setEditPropertyFields] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [formattedPropertyCallbox, setFormattedPropertyCallbox] = useState("");
  const [callboxMessage, setCallboxMessage] = useState("Welcome In");

  const timezones = [
    { id: "America/New York", label: "Eastern Time (ET)" },
    { id: "America/Chicago", label: "Central Time (CT)" },
    { id: "America/Denver", label: "Mountain Time (MT)" },
    { id: "America/Los Angeles", label: "Pacific Time (PT)" }
  ];

  // Change this to property detail equivalents i.e. updateProperty
  const { getSingleProperty, editProperty } = useContext(AuthContext);

  const location = useLocation();
  const { callboxPhone } = location.state || {};

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const navigate = useNavigate();

  useEffect(() => {
    if (!callboxPhone) {
      navigate("/properties");
    }
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function getPropertyDetails() {
      try {
        setLoading(true);
        setDisableButton(true);

        document.body.classList.add('disable-scroll');

        const getSinglePropertyResponse = await getSingleProperty(callboxPhone);
        
        setPropertyName(getSinglePropertyResponse?.data?.property?.property_name);
        setDigits(getSinglePropertyResponse?.data?.property?.callbox_digits);
        setPropertyType(getSinglePropertyResponse?.data?.property?.property_type);
        setTimezone(getSinglePropertyResponse?.data?.property?.property_tz);
        setPropertyCallbox(getSinglePropertyResponse?.data?.property?.callbox_phone_raw);
        setFormattedPropertyCallbox(getSinglePropertyResponse?.data?.property?.callbox_phone);
        setCallboxMessage(getSinglePropertyResponse?.data?.property?.callbox_message);
      } catch (e) {
        if (e === null || e.code === "ERR_NETWORK") {
          notifyError("Something went wrong. Please try again later.");
        } else {
          const responseStatus = e.response?.status;
  
          if (responseStatus === 401) {
            navigate("/properties?error=e401&op=ep");
          } else {
            navigate("/properties?error=e500&op=ep");
          }
        }
      } finally {
        setLoading(false);
        setDisableButton(false);

        document.body.classList.remove('disable-scroll');
      }
    }

    getPropertyDetails();
  }, []);

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const handleChange = (event) => {
    setPropertyType(event.target.value);
  };

  const editPropertyChange = (field, value) => {
    setEditPropertyFields({
      ...editPropertyFields,
      [field]: value
    });
  };

  const handleEditPropertyUpdate = () => {
    setUpdateProperty(!updateProperty);
  }

  const handleEditPropertySubmit = async (e) => {
    try {
      e.preventDefault();

      const formFields = {...editPropertyFields};

      if (handleEditPropertyValidation()) {
        if (!formFields['property-name'] || formFields['property-name'] === "")
          formFields['property-name'] = propertyName;

        if (!formFields['digits'] || formFields['digits'] === "")
          formFields['digits'] = digits;
        
        if (!formFields['timezone'] || formFields['timezone'] === "")
          formFields['timezone'] = timezone;

        const payload = {
          property_name: formFields['property-name'] === "" ? propertyName : formFields['property-name'],
          callbox_digits: formFields['digits'] === "" ? digits : formFields['digits'],
          callbox_phone: propertyCallbox,
          property_type: propertyType,
          property_tz: formFields['timezone'] === "" ? timezone : formFields['timezone'],
          callbox_message: formFields['message'],
          user_membership_plan: planName
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);

        document.body.classList.add('disable-scroll');

        await editProperty(payload);

        setPropertyName(formFields['property-name']);
        setDigits(formFields['digits']);
        setTimezone(formFields['timezone']);

        navigate("/properties?success=true&op=ep");
      }
    } catch (ex) {
      if (ex === null || ex.code === "ERR_NETWORK") {
        navigate("/properties?error=e500&op=ep");
      } else {
        const responseStatus = ex.response?.status;

        if (responseStatus === 401) {
          navigate("/properties?error=e401&op=ep");
        } else if (responseStatus === 400) {
          notifyError("Form is not valid. Please check your input.");
        } else {
          navigate("/properties?error=e500&op=ep");
        }
      }
    } finally {
      setLoading(false);
      setDisableBackButton(false);

      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
      }, 1500);
    }
  }
  
  const handleEditPropertyValidation = () => {
    var specialCharacterRegex = /[!#$%^&@.*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    var specialCharacterMessageRegex = /[#$%^&@*()_+\-=\[\]{};':"\\|<>\/]+/;
    var digitsRegex = /^\d+$/;

    const formFields = {...editPropertyFields};

    let formIsValid = true;

    if (!formFields['property-name']) {
      if (!propertyName || propertyName === "") {
        formIsValid = false;
        notifyError("Property name is invalid.");
      } else {
        formFields['property-name'] = propertyName;
      }
    } else if (specialCharacterRegex.test(formFields['property-name'])) {
      formIsValid = false;
      notifyError("Property name has invalid characters.");
    } else if (formFields['property-name'].length > 40) {
      formIsValid = false;
      notifyError("Property name is too long. Please use less than 40 characters.");
    } else if (!formFields['digits']) {
      if (!digits || digits === "") {
        formIsValid = false;
        notifyError("Digits can't be empty. Please provide at least one digit.");
      } else {
        formFields['digits'] = digits;
      }
    } else if (!digitsRegex.test(formFields['digits'])) {
      formIsValid = false;
      notifyError("Digits has invalid characters.");
    } else if (formFields['digits'].length > 10) {
      formIsValid = false;
      notifyError("Too many digits provided. Please provide less than 10.");
    } else if (!propertyType) {
      formIsValid = false;
      notifyError("Please select a property type.");
    } else if (!formFields['timezone']) {
      if (!timezone || timezone === "") {
        formIsValid = false;
        notifyError("Please select a timezone.");
      } else {
        formFields['timezone'] = timezone;
      }
    } else if (formFields['message'] && formFields['message'].length > 50) {
      formIsValid = false;
      notifyError("Gate entry message is too long.");
    } else if (formFields['message'] && specialCharacterMessageRegex.test(formFields['message'])) {
      formIsValid = false;
      notifyError("Gate entry message contains invalid characters.");
    }

    if (formIsValid)
      setPropertyType(propertyType);;

    return formIsValid;
  };

  const navigateBack = () => {
    navigate(-1);
  }

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="properties"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName}/>
          <div className="add-property">
            <GoBackButton isDisabled={disableBackButton}/>
            <div className="add-property-box mb-4">
              <div className="add-property-content">
                <Form onSubmit={handleEditPropertySubmit}>
                  <h3 className="heading-4 heading-4-semi-bold text-center mb-3" id="timelock-title">
                    Edit Property
                  </h3>
                  <CustomInput
                    label="Property Nickname"
                    placeholder={propertyName}
                    type="text"
                    disabled={!updateProperty}
                    onChange={e => editPropertyChange('property-name', e.target.value)} 
                    value={!updateProperty ? propertyName : editPropertyFields['property-name']}
                  />
                  <CustomInput
                    label="Callbox Phone Number"
                    placeholder={formattedPropertyCallbox}
                    value={formattedPropertyCallbox}
                    type="text"
                    disabled={true}
                  />
                  <CustomInput
                    label="Callbox Digits"
                    placeholder={digits}
                    type="text"
                    disabled={!updateProperty}
                    onChange={e => editPropertyChange('digits', e.target.value)} 
                    value={!updateProperty ? digits : editPropertyFields['digits']}
                  />
                  <CustomInput
                    label="Gate Entry Message"
                    placeholder={callboxMessage}
                    type="text"
                    onChange={e => editPropertyChange('message', e.target.value)} value={!updateProperty ? callboxMessage : editPropertyFields['message']}
                    disabled={!updateProperty || (planName && planName === "Basic")}
                  />
                  <h6 className="heading-6 heading-6-semi-bold">
                    Property Type
                  </h6>
                  <Row className="radio-inputs">
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="apartment"
                          disabled={!updateProperty}
                          checked={propertyType === "apartment"}
                        />
                        Apartment
                      </label>
                    </Col>
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="house"
                          disabled={!updateProperty}
                          checked={propertyType === "house"}
                        />
                        House
                      </label>
                    </Col>
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="condo"
                          disabled={!updateProperty}
                          checked={propertyType === "condo"}
                        />
                        Condo
                      </label>
                    </Col>
                  </Row>
                  <CustomInput
                    label="Property Timezone"
                    placeholder={timezone}
                    type={!updateProperty ? "text" : "dropdown"}
                    onChange={e => editPropertyChange('timezone', e.target.value)} 
                    value={!updateProperty ? timezone : editPropertyFields['timezone']}
                    disabled={!updateProperty}
                  >
                    {timezones.map(tz => (
                      <option key={tz.id} value={tz.id}>
                        {tz.label}
                      </option>
                      ))
                    }
                  </CustomInput>
                  { !updateProperty &&
                    <div>
                      <Button variant="primary"
                        className={isMobile ? "primary-btn mt-3 mb-3 create-prp" :"primary-btn mt-3 mb-3 create-prp"}
                        disabled={disableButton}
                        onClick={handleEditPropertyUpdate}>
                          Click to Edit
                      </Button>
                    </div>
                  }
                  { updateProperty &&
                    <>
                      <div>
                        <Button variant="primary"
                          className={isMobile ? "primary-btn mt-3 mb-3 create-prp" :"primary-btn mt-3 mb-3 create-prp"}
                          disabled={disableButton}
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                      <div>
                        <Button variant="secondary"
                          className={isMobile ? "primary-btn2 mt-3 mb-3 del-prp" :"primary-btn2 mb-3 del-prp"}
                          disabled={disableButton}
                          onClick={navigateBack}
                          >
                            Cancel
                        </Button>
                      </div>
                    </>
                  }
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
}

export default EditProperty;