import { Col, Container, Image, Row, Button } from "react-bootstrap";

import check from "../../../assets/images/check.svg";

import { useState, useEffect } from "react";

const Pricing = () => {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmall(window.innerWidth < 1260 && window.innerWidth > 991);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const pricing_content = [
    {
      id: 1,
      name: "Basic Tier",
      description: "",
      list: {
        item1: isSmall ? "1 Property Supported" : "1 Property Supported",
        item2: isSmall ? "Generic Callbox Message" : "Generic \"Welcome In\" Callbox Message",
        item3: isSmall ? "1 Phone Number" : "1 GateTeck Phone Number",
        item4: isSmall ? "Customer Support" : "Customer Support via Email",
      },
      price: "$9.99",
      price_desc: "/mo",
      btn_text: isSmall ? "Subscribe" : "Choose Plan",
      disabled: false,
      type: "",
    },
    {
      id: 2,
      name: "Host Tier",
      description: "",
      list: {
        item1: isSmall ? "5 Properties Supported" : "5 Properties Supported",
        item2: isSmall ? "Customizable Callbox Messages" : "Customizable Callbox Messages",
        item3: isSmall ? "TimeLock" : "TimeLock Functionality",
        item4: isSmall ? "1 Phone Number" : "1 GateTeck Phone Number",
        item5: isSmall ? "Customer Support" : "Customer Support via Email",
        button_sub_text: "",
        is_free_trial: false,
      },
      price: "Free*",
      price_desc: isSmall ? "$29.99 /mo" : "$29.99 /mo",
      btn_text: isSmall ? "Try It" : "Start Your Free Trial",
      disabled: false,
      button_sub_text: isSmall ? "* $29.99 after 14 days. New users only." : "* $29.99 after 14 day trial ends. Offer is available to new users only.",
      is_free_trial: true,
      type: "",
    },
    {
      id: 3,
      name: "Entrepreneur Tier",
      description: "",
      list: {
        item1: isSmall ? "Ideal For Real Estate Groups" : "Perfect For Real Estate Groups",
        item2: isSmall ? "Property Count That Supports Your Needs" : "Property Count That Supports Your Needs",
        item3: isSmall ? "Tailored Plan" : "Tailored Plan",
        item4: isSmall ? "Everything in Host Tier" : "Everything in Host Tier",
      },
      price: "$--.--*",
      price_desc: "",
      btn_text: isSmall ? "Let's Talk" : "Let's Talk",
      disabled: false,
      button_sub_text: isSmall ? "* Enterprise pricing available." : "* Enterprise pricing is available.",
      is_free_trial: false,
      type: "lets-talk",
    },
  ];

  return (
    <div className="pricing-section" id="pricing">
      <Container>
        <Row>
          <Col className="text-center heading mb-4">
            <h1 className="heading-1 heading-1-semi-bold">Pricing Plans</h1>
          </Col>
        </Row>
        <Row>
          {pricing_content.map((content) => (
            <Col lg={4} className="mb-4 mb-lg-0">
              <div
                className={`pricing-box d-flex justify-content-between flex-column text-center ${content.name === "Host Tier" ? "middle" : ""
                  }`}
              >
                <div className="content">
                  <h4 className="heading-4 heading-4-semi-bold">
                    {content.name}
                  </h4>
                  <p className="paragraph-main paragraph-main-regular mb-5">
                    {content.description}
                    <hr></hr>
                  </p>
                  <div className="listItems text-start">
                    {content.list?.item1 && <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item1}
                      </p>
                    </div>}
                    {content.list?.item2 && <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item2}
                      </p>
                    </div>}
                    {content.list?.item3 && <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item3}
                      </p>
                    </div>}
                    {content.list?.item4 && <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item4}
                      </p>
                    </div>}
                    {content.list?.item5 && <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item5}
                      </p>
                    </div>}
                  </div>
                </div>
                <div className="price">
                  <div className="d-flex justify-content-center flex-row">
                    <h1 className="heading-1 heading-1-semi-bold">
                      {content.price}
                    </h1>
                    <p className="pricing-footer text-center align-items-center d-flex justify-content-center price-desc">
                      {content.is_free_trial && <s>{content.price_desc}</s>}
                      {!content.is_free_trial && content.price_desc}
                    </p>
                  </div>
                  <a href={content.disabled ? "" : content.type === "lets-talk" ? "/request-a-quote" : "/signup"}>
                    <Button
                      className={`heading-6 heading-6-bold ${content.name === "Entrepreneur Tier"
                        ? "pricing-btn-light"
                        : "pricing-btn"
                        }`}
                      disabled={content.disabled}
                    >
                      {content.btn_text}
                    </Button>
                  </a>
                  <p className="pricing-subfooter text-center price-desc mt-4">
                    {content.button_sub_text}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="w-100 mt-5">
        <p className="text-center pricing-footer">Have any questions?<br></br><a href="/form" className="pricing-footer-href">Contact us</a> for any inquiries or concerns.</p>
      </div>
    </div>
  );
};

export default Pricing;
